<template>
  <div>
    <b-navbar toggleable="lg" variant="primary">
      <template v-if="!isAuthorized">
        <b-navbar-nav>
          <b-nav-item v-bind:to="'/login'">Вход</b-nav-item>
        </b-navbar-nav>
      </template>
      <template v-else>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item v-bind:to="'/import'">Импорт каталога</b-nav-item>
            <b-nav-item v-bind:to="'/receipt'">Импорт поступлений</b-nav-item>
            <b-nav-item v-bind:to="'/banner'">Создание баннеров</b-nav-item>
          </b-navbar-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item @click="logoutClicked" right>Выход</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </template>
    </b-navbar>
    <b-container class="pt-2">
      <router-view></router-view>
    </b-container>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "catalog-app",
  mounted() {
    document.title = "Каталог";
  },
  computed: {
    ...mapGetters([
      "isAuthorized",
      "isAdmin"
    ])
  },
  methods: {
    ...mapActions(["logout"]),
    logoutClicked() {
      this.logout()
          .finally(() => {
            this.$router.push("/login");
          });
    }
  }
};
</script>
