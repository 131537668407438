<template>
  <b-input-group class="mt-1">
    <b-input type="text" :placeholder="text" v-model="propModel"/>
    <template #append>
      <b-button variant="outline" class="grey-border" @click="propModel=null">
        <b-icon-x-circle variant="danger"/>
      </b-button>
    </template>
  </b-input-group>
</template>

<script>
export default {
  props: {
    text: String,
    inputModel: String
  },
  computed: {
    propModel: {
      get() {
        return this.inputModel
      },
      set(value) {
        this.inputModel = value
        this.$emit("updateModel", value)
      }
    }

  }
}
</script>