<template>
  <div>
    <div v-if="isRolesEmpty">
      <h1 class="my-4">У вас нет доступа к данному сервису</h1>
    </div>
    <div v-else>
      <filter-card v-model:filters="filters" @refresh="$refs.products_table.refresh()"/>
      <b-table
          hover
          responsive
          :items="getItems"
          :fields="fields"
          ref="products_table"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :per-page="productList.perPage"
          :current-page="productList.currentPage"
          class="table-bordered table-hover"
          show-empty>
        <template v-slot:empty="scope">
          <h6 class="text-center">Товары отсутствуют</h6>
        </template>
        <template v-slot:table-busy>
          <loading-spinner/>
        </template>

        <template v-slot:cell(thumbnail)="row">
          <b-img :src="row.item.thumbnail[0]" alt="Миниатюра товара" style="max-height: 150px; max-width: 150px;"/>
        </template>
        <template v-slot:cell(weighed)="row">
          <div class="text-center">
            <b-icon-check-circle scale="2" v-if="row.item.weighed" variant="success"/>
            <b-icon-x-circle scale="2" v-else variant="danger"/>
          </div>
        </template>
        <template v-slot:cell(amount)="row">
          <product-amount :index="row.index" :item="row.item" :edit-enabled="editEnabled" :editing-id="editingId"
                          @changeEditValues="changeEditValues"/>
        </template>
      </b-table>
      <div class="d-flex pb-1">
        <div class="col-md-2 pl-0">
          <b-form-select
              v-model="productList.perPage"
              class="text-light border-secondary bg-secondary">
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="30">30</option>
          </b-form-select>
        </div>
        <span class="align-self-center d-none d-lg-block mb-2">записей на страницу</span>
        <b-pagination
            v-model="productList.currentPage"
            class="my-0 ml-auto mb-2"
            :total-rows="productList.totalCount"
            :per-page="productList.perPage"
            aria-controls="template-table"
            @input="getItems">
        </b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import catalog from "@/modules/catalog";
import ProductAmount from "@/views/stock/ProductAmount.vue";
import FilterCard from "@/components/FilterCard.vue";

export default {
  name: 'ProductList',
  components: {FilterCard, ProductAmount, LoadingSpinner},
  data() {
    return {
      sortBy: 'amount',
      sortDesc: false,
      submitting: false,
      editingId: -1,
      editEnabled: [],
      fields: [
        {key: 'thumbnail', label: 'Товар', sortable: false},
        {
          key: 'provider', label: 'Поставщик', sortable: false,
          thClass: this.isAdminOrManager ? 'd-none' : '',
          tdClass: this.isAdminOrManager ? 'd-none' : ''
        },
        {key: 'category_name', label: 'Категория', sortable: true},
        {key: 'name', label: 'Наименование', sortable: true},
        {key: 'product_code', label: 'Артикул', sortable: true},
        {key: 'weighed', label: 'Весовой', sortable: true},
        {key: 'amount', label: 'Остаток', sortable: true},
      ],
      filters: {
        productName: '',
        productCode: '',
        providerName: '',
        providerCode: '',
      },
      productList: {
        perPage: 5,
        currentPage: 1,
        totalCount: 0,
      },
    }
  },
  mounted() {
    document.title = 'Список товаров'
  },
  computed: {
    ...mapGetters([
      "isAuthorized",
      "isAdminOrManager",
      "isRolesEmpty",
    ]),
  },
  methods: {
    getItems(context) {
      if (!this.sortBy) {
        return
      }

      this.editingId = -1
      return catalog.getProductsForProvider(this.filters,
          context.perPage, context.currentPage,
          context.sortBy === "category_name" ? "category_id" : context.sortBy,
          context.sortDesc ? "Descending" : "Ascending")
          .then(resp => {
            if (resp.status === 204) {
              this.$bvToast.toast('Товары не найдены', {
                title: 'Ошибка',
                variant: 'danger',
                autoHideDelay: 5000,
                appendToast: true
              })
              return;
            }
            this.editEnabled = Array.from({length: resp.data.products.length}, () => false)
            this.productList.totalCount = resp.data.count
            return resp.data.products;
          })
          .catch(error => {
            this.$bvToast.toast('Не удалось загрузить товары' + (error.response == null ? '' : ` (код ${error.response.status})`), {
              title: 'Ошибка',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: true
            });
          });
    },
    changeEditValues(index, editing) {
      if (editing) {
        if (!this.editEnabled[index]) {
          return;
        }

        this.$set(this.editEnabled, index, false)
        this.editingId = -1
        return;
      }

      this.editingId = index
      this.$set(this.editEnabled, index, true)
    }
  }
}
</script>
