<template>
  <div>
    <b-form @submit.prevent="onSubmit" method="post">
      <phone-number-input @update="phoneInput"
                          size="lg"
                          :value="phone"
                          :error="phoneError"
                          :translations="translations"
                          :default-country-code="country.name"
                          no-example
                          :countries-height="-1"
                          :only-countries="[country.name]">
        <template #arrow>
          <div></div>
        </template>
      </phone-number-input>
      <div class="mt-3" v-if="loginSent">
        <div class="verification-label">
          Код подтверждения
        </div>
        <code-input :autoFocus="true" class="verification-code" @complete="codeOnComplete" :loading="false"
                    :fields="4"/>
        <div class="time">
          Оставшееся время: {{ secondsLeft }} сек.
        </div>
        <div class="error-message">
          {{ authError }}
        </div>
        <b-button :disabled="secondsLeft !== 0 && secondsLeft != null" type="submit" variant="primary"
                  class="mt-3 mx-auto form-control">
          Выслать код повторно
        </b-button>
      </div>
      <b-button v-if="!loginSent" type="submit" variant="primary" class="mt-3 mx-auto form-control"
                :disabled="!phoneValid || loading">Отправить код в СМС
      </b-button>
      <b-alert class="mt-2 mb-0 small" variant="danger" :show="loginError != null" dismissible
               @dismissed="loginError=null">
        {{ loginError }}
      </b-alert>
      <loading-spinner class="mt-3" v-if="loading"/>
    </b-form>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/LoadingSpinner";
import {mapActions} from "vuex";

export default {
  name: "otp-auth",
  components: {
    'loading-spinner': LoadingSpinner
  },
  data() {
    return {
      loading: false,
      phone: null,
      phoneError: false,
      authError: null,
      loginError: null,
      loginSent: false,
      secondsLeft: null,
      translations: {
        phoneNumberLabel: 'Номер телефона',
        countrySelectorLabel: 'Код страны'
      },
      country: {
        name: "RU",
        code: "+7"
      }
    }
  },
  computed: {
    phoneValid() {
      const phoneValid = /^\d\d\d-\d\d\d-\d\d-\d\d$/.test(this.phone);
      this.phoneError = this.phone != null && !phoneValid;
      return phoneValid;
    }
  },
  methods: {
    ...mapActions(["login", "auth"]),
    phoneInput(event) {
      if (event.phoneNumber?.length > (this.phone || '').length && !/\d/.test(event.phoneNumber.slice(-1))) {
        return;
      }

      this.phoneError = !event.isValid && event.phoneNumber?.length < 14;
      if (event.phoneNumber == null || event.phoneNumber.length < 14) {
        let newNumber = event.phoneNumber;
        if (newNumber != null && this.phone != null) {
          if (newNumber.length > this.phone.length) {
            if (newNumber.length === 3 || newNumber.length === 7 || newNumber.length === 10) {
              newNumber += "-";
            }
          } else if (this.phone.slice(-1) === '-') {
            newNumber = newNumber.slice(0, -1);
          }
        }
        this.phone = newNumber;
        this.loginSent = false;
      }
    },
    onSubmit() {
      this.loginError = null;
      this.authError = null;
      this.loading = true;
      this.login(this.createFullPhoneNumber())
          .then(response => {
            this.secondsLeft = response.data.ttl;
            this.loginSent = true;
            this.countDown();
          })
          .catch(err => {
            if (err.response != null) {
              this.loginError = `Ошибка при входе (код ${err.response.status})`;
            } else {
              this.loginError = 'Неизвестная ошибка при входе';
            }
          })
          .finally(() => {
            this.loading = false;
          });
    },
    countDown() {
      setTimeout(() => {
        if (--this.secondsLeft > 0) {
          this.countDown();
        }
      }, 1000);
    },
    codeOnComplete(code) {
      this.authError = null;
      this.loading = true;
      this.auth({
        phone: this.createFullPhoneNumber(),
        code: code
      }).then(() => {
        this.$router.push('/import');
      }).catch(err => {
        if (err.response != null) {
          if (err.response.status === 400) {
            this.authError = 'Неверный код подтверждения';
          } else if (err.response.status === 404) {
            this.authError = 'Время действия кода истекло';
          } else {
            this.authError = `Произошла ошибка при авторизации (код ${err.response.status})`;
          }
        } else {
          this.authError = 'Произошла ошибка при авторизации';
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    createFullPhoneNumber() {
      return `${this.country.code}-${this.phone}`;
    }
  }
}
</script>
<style scoped>
/deep/ .select-country-container {
  flex: 0 0 100px !important;
  width: 100px !important;
  min-width: 100px !important;
  max-width: 100px !important;
}

.time {
  text-align: center;
  font-size: 14px;
}

.verification-code {
  margin: auto;
}

.verification-label {
  font-size: 12px;
  text-align: center;
}

.error-message {
  color: red;
  font-size: 14px;
  text-align: center;
}
</style>
