<template>
  <div class="py-2">
    <b-button v-b-toggle.filters-collapse variant="primary">Фильтры</b-button>
    <b-collapse id="filters-collapse" class="mt-2">
      <b-card>
        <b-row>
          <b-col>
            <b-row cols-lg="2" cols-md="1" cols-sm="1" cols="1" no-gutters>
              <b-col class="pb-sm-1">
                <input-group :inputModel="filters.productName" text="Наименование товара"
                             @updateModel="updateProductName"/>
              </b-col>
              <b-col>
                <input-group :inputModel="filters.productCode" text="Артикул товара"
                             @updateModel="updateProductCode"/>
              </b-col>
              <b-col v-if="isAdminOrManager" class="pt-1">
                <input-group :inputModel="filters.providerName" text="Наименование поставщика"
                             @updateModel="updateProviderName"/>
              </b-col>
              <b-col v-if="isAdminOrManager" class="pt-1 pb-1">
                <input-group :inputModel="filters.providerCode" text="Код поставщика"
                             @updateModel="updateProviderCode"/>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" md="auto">
            <div class="pt-1 pb-1">
              <b-button variant="primary" @click="handleClick" class="w-100">Поиск</b-button>
            </div>
            <div class="pt-2 pb-1" v-if="isAdminOrManager">
              <b-button variant="primary" @click="clearSearch" class="w-100">Очистить всё</b-button>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </b-collapse>
  </div>
</template>

<script>
import InputGroup from "@/components/InputGroup.vue";
import {mapGetters} from "vuex";

export default {
  name: 'filter-card',
  components: {InputGroup},
  model: {
    prop: 'filters',
    event: 'update:filters'
  },
  props: {
    filters: {
      type: Object,
      required: true,
    }
  },
  computed: {
    ...mapGetters([
      "isAdminOrManager",
    ]),
  },
  methods: {
    handleClick() {
      this.$emit("update:filters", this.filters)
      this.$emit('refresh')
    },
    clearSearch() {
      this.filters.providerName = '';
      this.filters.providerCode = '';
      this.filters.productName = '';
      this.filters.productCode = '';
      this.$emit("update:filters", this.filters)
    },
    updateProductName(value) {
      this.filters.productName = value
      this.$emit("update:filters", this.filters)
    },
    updateProductCode(value) {
      this.filters.productCode = value
      this.$emit("update:filters", this.filters)
    },
    updateProviderName(value) {
      this.filters.providerName = value
      this.$emit("update:filters", this.filters)
    },
    updateProviderCode(value) {
      this.filters.providerCode = value
      this.$emit("update:filters", this.filters)
    },
  }
}
</script>
<style>
.grey-border {
  border-color: rgb(206, 212, 218)
}
</style>
