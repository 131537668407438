<template>
  <div>
    <b-navbar v toggleable="lg" variant="primary">
      <b-navbar-nav v-if="!isAuthorized">
        <b-nav-item v-bind:to="'/login'">Вход</b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto" v-else>
        <b-nav-item @click="logoutClicked">Выход</b-nav-item>
      </b-navbar-nav>
    </b-navbar>
    <b-container class="pt-2">
      <router-view/>
    </b-container>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "catalog-app",
  mounted() {
    document.title = "Каталог";
  },
  computed: {
    ...mapGetters([
      "isAuthorized",
      "isAdmin"
    ])
  },
  methods: {
    ...mapActions(["logout"]),
    logoutClicked() {
      this.logout()
          .finally(() => {
            this.$router.push("/login");
          });
    }
  }
};
</script>
