<template>
  <div v-if="!isAdmin">
    <h1 class="my-4">У вас нет доступа к данному сервису</h1>
  </div>
  <div v-else>
    <div>
      <b-form @submit.stop.prevent="onSubmit">
        <b-form-group label="Каталог товаров:" label-size="lg">
          <b-form-file
              v-model="$v.catalogImportFile.$model"
              :state="$v.catalogImportFile.$dirty ? !$v.catalogImportFile.$error : null"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              ref="XLSX"
              placeholder="Выберите или перетащите файл импорта каталога сюда"
              drop-placeholder="Перетащите файл сюда"
              browse-text="Выбрать"/>
          <b-form-invalid-feedback v-if="!$v.catalogImportFile.required">
            Не указан файл импорта
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Архив изображений:" label-size="lg">
          <b-form-file
              v-model="imagesZipFile"
              accept="application/zip"
              ref="ZIP"
              placeholder="Выберите или перетащите архив изображений сюда"
              drop-placeholder="Перетащите архив сюда"
              browse-text="Выбрать"/>
        </b-form-group>
        <b-form-group>
          <b-form-checkbox v-model="importReviewCheck">Импортировать отзывы</b-form-checkbox>
        </b-form-group>
        <b-button variant="primary" class="mb-2" :disabled="$v.$anyError" type="submit">
          <div v-if="submitting">
            <b-spinner small></b-spinner>
            Отправка
          </div>
          <div v-else>Отправить</div>
        </b-button>
      </b-form>
    </div>
    <div>
      <b-alert v-model="isSubmitted" dismissible
               :variant="this.response.catalogErrors.length > 0 ? 'danger'
                  : this.response.imageErrors.length > 0 ? 'warning' : 'success'">
        <h4 class="alert-heading" v-if="response.catalogErrors.length > 0">Импорт каталога завершился ошибкой!</h4>
        <h4 class="alert-heading" v-else>Импорт каталога выполнено успешно!</h4>
        <div>
          <b-card-group deck>
            <b-card no-body border-variant="dark"
                    header="Импортировано категорий"
                    header-bg-variant="transparent"
                    header-text-variant="dark"
                    align="center">
              <b-card-body>
                {{ response.processedCategoryCount }} из {{ response.totalCategoryCount }}
              </b-card-body>
            </b-card>
            <b-card no-body border-variant="dark"
                    header="Импортировано поставщиков"
                    header-bg-variant="transparent"
                    header-text-variant="dark"
                    align="center">
              <b-card-body>
                {{ response.processedProviderCount }} из {{ response.totalProviderCount }}
              </b-card-body>
            </b-card>
          </b-card-group>
          <b-card-group class="mt-3"  deck>
            <b-card no-body border-variant="dark"
                    header="Импортировано товаров"
                    header-bg-variant="transparent"
                    header-text-variant="dark"
                    align="center">
              <b-card-body>
                {{ response.processedProductCount }} из {{ response.totalProductCount }}
              </b-card-body>
            </b-card>
            <b-card v-if="response.processedImageCount !== 0"
                    no-body border-variant="dark"
                    header="Импортировано изображений"
                    header-bg-variant="transparent"
                    header-text-variant="dark"
                    align="center">
              <b-card-body>
                {{ response.processedImageCount }}
              </b-card-body>
            </b-card>
          </b-card-group>
          <b-card-group class="mt-3"  deck>
            <b-card v-if="response.totalReviewCount !== 0"
                    no-body border-variant="dark"
                    header="Импортировано отзывов"
                    header-bg-variant="transparent"
                    header-text-variant="dark"
                    align="center">
              <b-card-body>
                {{ response.processedReviewCount }} из {{ response.totalReviewCount }}
              </b-card-body>
            </b-card>
          </b-card-group>
          <b-card-group class="mt-3" v-if="response.catalogErrors.length > 0">
            <b-card v-if="response.catalogErrors.length > 0" border-variant="dark"
                    header="Ошибки при импорте каталога"
                    header-bg-variant="transparent"
                    header-text-variant="dark"
                    align="center">
              <div v-for="r in response.catalogErrors">
                {{ r.localizedMessage }}
              </div>
            </b-card>
          </b-card-group>
          <b-card-group class="mt-3" v-if="response.imageErrors.length > 0">
            <b-card border-variant="dark"
                    header="Ошибки при импорте архива изображений"
                    header-bg-variant="transparent"
                    header-text-variant="dark"
                    align="center">
              <div v-for="r in response.imageErrors">
                {{ r.localizedMessage }}
              </div>
            </b-card>
          </b-card-group>
        </div>
        <br>
        <div class="d-flex justify-content-between">
          Длительность импорта: {{ loadTime / 1000 }} сек.
          <b-icon-save shift-v="3" title="Сохранить отчет" font-scale="1.5" @click="downloadReport()"></b-icon-save>
        </div>
      </b-alert>
    </div>
  </div>
</template>

<script>
import {AXIOS} from "@/modules/backend-config";
import {required} from "vuelidate/lib/validators";
import {mapGetters} from "vuex";
import {BIconSave2Fill} from 'bootstrap-vue';
import moment from 'moment';

export default {
  name: 'ImportCatalog',
  computed: {
    ...mapGetters([
        "isAdmin"
    ])
  },
  components: {
    'b-icon-save': BIconSave2Fill
  },
  validations() {
    return {
      catalogImportFile: {
        required
      }
    };
  },
  data() {
    return {
      response: {
        totalProductCount: null,
        totalCategoryCount: null,
        totalProviderCount: null,
        totalReviewCount: null,
        processedProductCount: null,
        processedCategoryCount: null,
        processedProviderCount: null,
        processedReviewCount: null,
        processedImageCount: null,
        catalogErrors: [],
        imageErrors: []
      },
      loadTime: null,
      catalogImportFile: null,
      imagesZipFile: null,
      importReviewCheck: false,
      submitting: false,
      isSubmitted: false,
    }
  },
  methods: {
    downloadReport() {
      let link = document.createElement('a');
      link.download = `Отчет о результатах импорта(${moment().format('DD-MM-YYYY')}).txt`;
      let blob = new Blob([this.getReportText()], {type: 'text/plain'});
      link.href = URL.createObjectURL(blob);
      link.click();
      URL.revokeObjectURL(link.href);
    },
    getReportText() {
      let report = '';

      report += this.response.catalogErrors.length > 0 ? 'Импорт каталога завершился ошибкой!\n' : 'Импорт каталога выполнено успешно!\n';
      report += `Импортировано категорий: ${this.response.processedCategoryCount} из ${this.response.totalCategoryCount}\n`;
      report += `Импортировано товаров: ${this.response.processedProductCount} из ${this.response.totalProductCount}\n`;
      report += `Импортировано поставщиков: ${this.response.processedProviderCount} из ${this.response.totalProviderCount}\n`;
      if (this.response.totalReviewCount !== 0) {
        report += `Импортировано отзывов: ${this.response.processedReviewCount} из ${this.response.totalReviewCount}\n`;
      }
      if (this.response.processedImageCount !== 0) {
        report += `Импортировано изображений: ${this.response.processedImageCount}\n`
      }
      if (this.response.catalogErrors.length > 0) {
        report += 'Ошибки при импорте каталога:\n';
        for (let i = 0; i < this.response.catalogErrors.length; i++) {
          report += `\t${this.response.catalogErrors[i].localizedMessage}\n`;
        }
      }
      if (this.response.imageErrors.length > 0) {
        report += 'Ошибки при импорте архива изображений:\n';
        for (let i = 0; i < this.response.imageErrors.length; i++) {
          report += `\t${this.response.imageErrors[i].localizedMessage}\n`;
        }
      }
      report += `Длительность импорта: ${this.loadTime / 1000} сек.\n`;

      return report;
    },
    onSubmit() {
      this.$v.catalogImportFile.$touch();
      if (this.$v.catalogImportFile.$anyError) {
        return;
      }

      this.isSubmitted = false;
      this.submitting = true;

      let formData = new FormData();
      formData.append('XLSX', this.catalogImportFile);

      if (this.imagesZipFile !== null) {
        formData.append('ZIP', this.imagesZipFile);
      }
      formData.append('INCLUDE_REVIEWS', this.importReviewCheck);

      let start = Date.now();
      return AXIOS.post(`/import/catalog`,
          formData
      ).then((r) => {
        this.response = r.data;
        this.isSubmitted = true;
      }).catch((e) => {
        this.$bvToast.toast(e.response.status === 400 ? e.response.data.message : 'Возникла неизвестная ошибка', {
          title: 'Ошибка',
          autoHideDelay: 5000,
          appendToast: true
        });
      }).finally(() => {
        this.loadTime = Date.now() - start;
        this.submitting = false;
      });
    }
  }
}
</script>
