<template>
  <div v-if="!isAdmin">
    <h1 class="my-4">У вас нет доступа к данному сервису</h1>
  </div>
  <div v-else>
    <div>
      <b-form @submit.stop.prevent="onSubmit">
        <b-form-group label="Файл импорта поступлений:" label-size="lg">
          <b-form-file
              v-model="$v.receiptImportFile.$model"
              :state="$v.receiptImportFile.$dirty ? !$v.receiptImportFile.$error : null"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              ref="XLSX"
              placeholder="Выберите или перетащите файл импорта поступлений сюда"
              drop-placeholder="Перетащите файл сюда"
              browse-text="Выбрать"/>
          <b-form-invalid-feedback v-if="!$v.receiptImportFile.required">
            Не указан файл импорта
          </b-form-invalid-feedback>
        </b-form-group>
        <b-button variant="primary" class="mb-2" :disabled="$v.$anyError" type="submit">
          <div v-if="submitting">
            <b-spinner small></b-spinner>
            Отправка
          </div>
          <div v-else>Отправить</div>
        </b-button>
      </b-form>
    </div>
    <div>
      <b-alert v-model="isSubmitted" dismissible
               :variant="this.response.errors.length > 0 ? 'danger' : 'success'">
        <h4 class="alert-heading" v-if="response.errors.length > 0">Импорт поступлений завершился ошибкой!</h4>
        <h4 class="alert-heading" v-else>Импорт поступлений выполнен успешно!</h4>
        <div>
          <b-card-group class="mt-3" v-if="response.errors.length > 0">
            <b-card v-if="response.errors.length > 0" border-variant="dark"
                    header="Ошибки при импорте поступлений:"
                    header-bg-variant="transparent"
                    header-text-variant="dark"
                    align="center">
              <div v-for="error in response.errors">
                {{ error }}
              </div>
            </b-card>
          </b-card-group>
        </div>
      </b-alert>
    </div>
  </div>
</template>

<script>
import {AXIOS} from "@/modules/backend-config";
import {required} from "vuelidate/lib/validators";
import {mapGetters} from "vuex";

export default {
  name: 'ImportReceipt',
  computed: {
    ...mapGetters([
      "isAdmin"
    ])
  },
  validations() {
    return {
      receiptImportFile: {
        required
      }
    };
  },
  data() {
    return {
      response: {
        initialProductCount: null,
        processedProductCount: null,
        errors: []
      },
      receiptImportFile: null,
      submitting: false,
      isSubmitted: false,
    }
  },
  methods: {
    onSubmit() {
      this.$v.receiptImportFile.$touch();
      if (this.$v.receiptImportFile.$anyError) {
        return;
      }

      this.isSubmitted = false;
      this.submitting = true;

      let formData = new FormData();
      formData.append('file', this.receiptImportFile);

      return AXIOS.post(`/import/receipt`,
          formData
      ).then((r) => {
        this.response = r.data;
        this.isSubmitted = true;
      }).catch(() => {
        this.$bvToast.toast('Возникла неизвестная ошибка', {
          title: 'Ошибка',
          autoHideDelay: 5000,
          appendToast: true
        });
      }).finally(() => {
        this.submitting = false;
      });
    }
  }
}
</script>
