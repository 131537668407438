import Vue from 'vue';
import Router from 'vue-router';
import Login from './views/Login.vue';
import ImportCatalog from './views/ImportCatalog.vue';
import ImportReceipt from './views/ImportReceipt.vue';
import CreateBanner from './views/CreateBanner.vue';
import store from './store';
import ProductsList from "@/views/stock/ProductsList.vue";
import CatalogPage from "@/components/CatalogPage.vue";
import StockPage from "@/components/StockPage.vue";

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: '/',
    routes: [
        {
            path: '/catalog', component: CatalogPage, meta: {title: 'Каталог'},
            children: [
                {path: '/import', component: ImportCatalog, meta: {title: 'Импорт каталога'}},
                {path: '/receipt', component: ImportReceipt, meta: {title: 'Импорт поступлений'}},
                {path: '/banner', component: CreateBanner, meta: {title: 'Создание баннеров'}},
                {path: '/login', component: Login, meta: {title: 'Вход'}},
            ]
        },
        {
            path: '/stock', component: StockPage, meta: {title: 'Остатки'},
            children: [
                {path: '/list', component: ProductsList, meta: {title: 'Список товаров'}},
            ]
        },
    ]
});

router.beforeEach(async (to, from, next) => {
    if (store.getters.isAuthorized == null) {
        await store.dispatch("refreshToken")
            .catch(error => {
                if (error.response?.status !== 401) {
                    console.error("Ошибка обновления токена", error);
                }
            });
    }

    const directingPath = to.path.endsWith('/') ? to.path.slice(0, -1) : to.path;

    if (!store.getters.isAuthorized && '/login' !== directingPath) {
        return next('/login');
    }
    if (store.getters.isAuthorized && '/login' === directingPath) {
        return next('/import');
    }
    if (!store.getters.isAdmin && '/banner' === directingPath) {
        return next(from.path);
    }
    if (store.getters.isAuthorized && '/stock' === directingPath) {
        return next('/list');
    }
    if ('/' === to.path) {
        return next('/catalog');
    }
    return next();
});

export default router;
