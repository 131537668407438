import {CATALOG_AXIOS} from './backend-config';
import store from "@/store";

export default {
    createBanner(serviceData) {
        const formData = new FormData();
        formData.append('file', serviceData.bannerImage);
        formData.append('deeplink', serviceData.deeplink);
        return CATALOG_AXIOS.post(`/banner`,
            formData, {
                headers: {
                    "Content-type": "multipart/form-data"
                }
            }
        );
    },
    getProductsForProvider(filters, pageSize, pageNumber, sortBy, sortDirection) {
        return CATALOG_AXIOS.post("/product/providerProducts", {}, {
            params: {
                providerPhone: store.state.phone,
                productName: filters.productName || null,
                productCode: filters.productCode || null,
                providerName: filters.providerName || null,
                providerCode: filters.providerCode || null,
                pageSize: pageSize,
                pageNumber: pageNumber,
                sortBy: sortBy,
                sortDirection: sortDirection,
            },
            headers: {
                'Content-Type': 'application/json'
            }
        });
    },
    saveAmount(amount, code) {
        return CATALOG_AXIOS.put("/product/amount", {
            productCode: code,
            productAmount: amount
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    },
}
