<template>
  <b-row style="min-width: 250px">
    <b-col class="flex-grow-1">
      <b-form-input id="number-validation"
                    type="number" v-model="item.amount" min="0" :disabled="!editEnabled[index]" placeholder="0"
                    :state="$v.item.amount.$dirty ? !$v.item.amount.$error : null"/>
      <b-form-invalid-feedback id="number-validation">
        Введите положительное число
      </b-form-invalid-feedback>
    </b-col>
    <b-col class="flex-grow-0">
      <b-button v-if="!editEnabled[index]"
                 class="w-100"
                variant="primary"
                @click="startEditing">
        <b-icon-pencil/>
      </b-button>
      <b-button v-else class="w-100"
                variant="success"
                @click="handleEditClick(item.amount, index, item.product_code)">
        <b-icon-check2/>
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import catalog from "@/modules/catalog";
import {minValue, requiredIf} from "vuelidate/lib/validators";

export default {
  name: "productAmount",
  data() {
    return {
      submitting: false,
    }
  },
  validations() {
    return {
      item: {
        amount: {
          required: requiredIf(() => isNaN(parseInt(this.item.amount))),
          minValue: (value) => value >= 0
        }
      }
    }
  },
  props: {
    item: {
      amount: Number,
      product_code: String,
    },
    index: Number,
    editingId: Number,
    editEnabled: Array,
  },
  methods: {
    startEditing() {
      this.$emit("changeEditValues", this.index, this.editingId !== -1)
    },
    handleEditClick(amount, index, productCode) {
      if (!this.submitting) {
        this.$v.item.$touch();
        if (this.$v.item.$anyError) {
          return;
        }
        this.$v.$reset();
        this.submitting = true;
        catalog.saveAmount(amount, productCode)
            .then(() => {
              this.$bvToast.toast('Количество товара успешно изменено', {
                title: 'Информация',
                variant: 'info',
                autoHideDelay: 3000,
                appendToast: true
              });
            })
            .catch(error => {
              this.$bvToast.toast('Не удалось сохранить количество товара: ' + (error.response == null ? '' : ` (код ${error.response.status})`), {
                title: 'Ошибка',
                variant: 'danger',
                autoHideDelay: 5000,
                appendToast: true
              });
            })
            .finally(() => {
              this.submitting = false;
            })
        this.$emit("changeEditValues", this.index, this.editingId !== -1)
        return;
      }

      this.$bvToast.toast('Происходит сохранение изменений.', {
        title: 'Операция отклонена',
        variant: 'warning',
        autoHideDelay: 3000,
        appendToast: true
      });
    }
  }
}
</script>